<template>
  <div class="company">
    <OurTeamDescription />
    <OurTeamGallery id="ourteam-gallery" />
    <GetStarted />
  </div>
</template>

<script>
import OurTeamDescription from "../components/OurTeamDescription.vue";
import OurTeamGallery from "../components/OurTeamGallery.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "company",
  components: {
    OurTeamDescription,
    OurTeamGallery,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis - Our Team",
      description:
        "We are a team of sharp, vibrant entrepreneurial individuals bringing our passion for technology and data to the internet. We continually seek opportunities to improve lives through technology.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "We are a team of sharp, vibrant entrepreneurial individuals bringing our passion for technology and data to the internet. We continually seek opportunities to improve lives through technology.";
    },
  },
};
</script>
